import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import Header2 from "../components/header/header2";
import Header3 from "../components/header/header3";

import MGCaption from "../components/MGCaption";
import NaviNext from "../components/NaviNext";
import { StaticImage } from "gatsby-plugin-image";

import Pie1 from "../components/histograms/Pie1";
import Pie2 from "../components/histograms/Pie2";
import SinglePhoto from "../components/SinglePhoto";

const Part7 = () => {
  return (
    <Tail>
      <Hero>
        <h1>
          РАЗДЕЛ 8. <br/>ПРОИЗВОДСТВЕННАЯ БЕЗОПАСНОСТЬ И ЭКОЛОГИЧЕСКАЯ
          ОТВЕТСТВЕННОСТЬ
        </h1>
      </Hero>

      <TextBlock>
        <Header2>8.1. ПРОМЫШЛЕННАЯ БЕЗОПАСНОСТЬ</Header2>
        <p>
          Достижение высокого уровня промышленной безопасности, повышение
          эффективности производственного контроля за соблюдением требований
          промышленной безопасности, снижение показателей аварийности, снижение
          рисков аварий и инцидентов и сведение к нулю производственного
          травматизма на опасных производственных объектах являются
          приоритетными задачами Общества.
        </p>
        <p className="mb-1">
          Основными направлениями деятельности Общества в области промышленной
          безопасности являются:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>соблюдение требований действующих нормативно-правовых актов;</p>
          </li>
          <li>
            <p>
              соблюдение эффективности функционирования производственного
              контроля на разных уровнях;
            </p>
          </li>
          <li>
            <p>
              страхование ответственности за причинение вреда при эксплуатации
              опасных объектов;
            </p>
          </li>
          <li>
            <p>
              повышение уровня знаний и компетенции сотрудников Общества в
              области промышленной безопасности;
            </p>
          </li>
          <li>
            <p>
              своевременное проведение экспертиз промышленной безопасности
              технических устройств опасных производственных объектов для
              обеспечения надежности работы опасных производственных объектов;
            </p>
          </li>
          <li>
            <p>
              предупреждение, локализация и ликвидация аварий и инцидентов на
              опасных производственных объектах;
            </p>
          </li>
          <li>
            <p>
              обеспечение готовности к действиям по локализации и ликвидации
              последствий аварий и инцидентов;
            </p>
          </li>
          <li>
            <p>
              проведение противоаварийных тренировок и командно-штабных учений с
              персоналом Общества по вопросам локализации и ликвидации аварийных
              ситуаций, в том числе в сложных климатических и стесненных
              условиях;
            </p>
          </li>
          <li>
            <p>
              получение, переоформление лицензии на право осуществления
              деятельности по эксплуатации взрывопожароопасных и химически
              опасных производственных объектов I, II, III классов опасности в
              Федеральной службе по экологическому, технологическому и атомному
              надзору;
            </p>
          </li>
          <li>
            <p>
              порядок проведения идентификации опасных производственных объектов
              Общества с последующей их регистрацией в государственном реестре
              опасных производственных объектов.
            </p>
          </li>
        </ul>
        <p>
          В целях обеспечения гарантированного и качественного выполнения всех
          мероприятий и работ по обеспечению промышленной безопасности на
          опасных производственных объектах Общества введена система
          четырехуровневого производственного контроля. Производственный
          контроль осуществляется путем проведения комплекса
          организационно-технических мероприятий, направленных на обеспечение
          безопасного функционирования опасных производственных объектов, а
          также на предупреждение аварий и инцидентов, приводящих к несчастным
          случаям на этих объектах и обеспечение готовности к локализации
          аварий, инцидентов и ликвидации их последствий.
        </p>
        <p>
          При осуществлении производственного контроля в 2021 году было
          проведено 270 проверок.
        </p>
        <p>
          АО «МОСГАЗ» осуществляет эксплуатацию сетей газораспределения и
          газопотребления, реализует комплекс мероприятий, включая мониторинг,
          техническое обслуживание, ремонт и аварийно-диспетчерское обеспечение
          сетей газораспределения и газопотребления, обеспечивающих содержание
          сетей газораспределения и газопотребления в исправном и безопасном
          состоянии.
        </p>
        <p>
          В 2021 году в Ростехнадзоре зарегистрирована сеть газоснабжения, в том
          числе межпоселковая УГВСД и ГРС по г. Москве. Всего в Обществе
          зарегистрировано 7 объектов газоснабжения и газопотребления как
          опасные производственные объекты, из них: 1 – II класса опасности и 6
          – III класса опасности.
        </p>
        <p>
          Обществом зарегистрировано 4 участка механизации, эксплуатирующие 54
          подъемных сооружения.
        </p>
        <MGCaption>Подъемные сооружения АО "МОСГАЗ"</MGCaption>
        <Pie1 idkey={"upbuild1"} />
        <p>В 2021 году выведено из эксплуатации 13 подъемных сооружений.</p>
        <p>
          В соответствии с Федеральным законом от 27.07.2010 № 225-ФЗ «Об
          обязательном страховании гражданской ответственности владельца
          опасного объекта за причинение вреда в результате аварии на опасном
          объекте» и с Положением о правилах обязательного страхования
          гражданской ответственности владельца опасного объекта за причинение
          вреда в результате аварии на опасном объекте, утвержденным Банком
          России 28.12.2016 № 574-П, все опасные производственные объекты
          газораспределения и газопотребления, а также участки механизации
          застрахованы.
        </p>
        <p>
          В 2021 году плановых и внеплановых проверок Федеральной службой по
          экологическому, технологическому и атомному надзору в отношении АО
          «МОСГАЗ» не проводилось.
        </p>
        <Header2>8.2. ОХРАНА ОКРУЖАЮЩЕЙ СРЕДЫ (ЭКОЛОГИЯ)</Header2>
        <p>
          В целях обеспечения экологической безопасности производственной
          деятельности АО «МОСГАЗ» предпринят ряд мер при соблюдении требований,
          закрепленных в Конституции Российской Федерации, федеральных законах
          Российской Федерации от 10.01.2002 № 7-ФЗ «Об охране окружающей
          среды», от 04.05.1999 № 96-ФЗ «Об охране атмосферного воздуха», от
          24.06.1998 № 89-ФЗ «Об отходах производства и потребления» и иных
          нормативных правовых актах».
        </p>
        <Header3>
          8.2.1. РАЦИОНАЛЬНОЕ ИСПОЛЬЗОВАНИЕ ПОЧВЕННОГО ПОКРОВА И ОБРАЩЕНИЕ С
          ОТХОДАМИ ПРОИЗВОДСТВА И ПОТРЕБЛЕНИЯ
        </Header3>
        <p>
          В АО «МОСГАЗ» уделяется особое внимание рациональному обращению с
          отходами. Практически все отходы, образованные Обществом в процессе
          производственной деятельности, идут на утилизацию (переработку). Это
          позволяет снизить негативное воздействие на окружающую среду и
          является экономически выгодным для предприятия, так как исключает
          необходимость внесения платы за размещение отходов.
        </p>
        <p>
          В результате реконструкции газовых сетей и сооружений, а также при
          строительстве новых объектов газоснабжения в 2021 году было образовано
          более 43 тысяч тонн строительных отходов и грунтов, которые были
          переданы на утилизацию в соответствующие организации, имеющие лицензии
          на данный вид деятельности.
        </p>
        <p>
          Экологическая безопасность грунтов и строительных отходов
          обеспечивается проведением лабораторных исследований, которые
          осуществляются только с привлечением аккредитованных соответствующим
          образом лабораторий.
        </p>
        <p>
          Благодаря раздельному сбору и сортировке отходов была достигнута
          максимальная передача отходов на утилизацию (использование)
          специализированным организациям, и в 2021 году их доля составила
          практически 97%.
        </p>
        <MGCaption>
          Распределение отходов на передачу сторонним организациям по видам
          обращения
        </MGCaption>
        <Pie2 idkey={"pie2"} />
        <p>
          В целях оперативного восстановления нарушенного благоустройства при
          проведении работ по ремонту, реконструкции и строительству объектов
          газового хозяйства г. Москвы в 2021 году АО «МОСГАЗ» после завершения
          землеройных работ было высажено 520 деревьев и 2207 кустарников.
        </p>
        <Header3>8.2.2. ОХРАНА АТМОСФЕРНОГО ВОЗДУХА</Header3>
        <p>
          Особое внимание АО «МОСГАЗ» уделяет охране атмосферного воздуха.
          Основными эксплуатирующими объектами Общества являются сети
          газоснабжения (в том числе межпоселковые) и газорегуляторные пункты
          (ГРП), предназначенные для снижения давления природного газа в
          газопроводе и автоматического поддержания постоянного входного
          давления природного газа для подачи потребителям. При нормальной
          эксплуатации газопровод не является источником выбросов загрязняющих
          веществ. Источниками залповых выбросов природного газа являются
          сбросные свечи от фильтров очистки (при ремонте и
          освидетельствовании), свечи блоков редуцирования, свечи
          предохранительных клапанов, установленные на ГРП.
        </p>
        <p>
          Нормирование вредных загрязняющих веществ производится на основании
          проведенных расчетов выбросов и расчетов рассеивания, формирование
          проектной документации для дальнейшей ее подачи в надзорные
          (контролирующие) органы.
        </p>
        <p>
          В соответствии со статьей 31.2 Федерального закона от 10.01.2002 №
          7-ФЗ «Об охране окружающей среды» для объектов II категории, к которым
          относятся производственные подразделения Общества, предусмотрена
          подача деклараций о воздействии на окружающую среду с предоставлением
          расчетов нормативов допустимых выбросов. За 2021 год собственными
          силами Общества были проведены расчеты выбросов и расчеты рассеивания,
          а также разработаны проекты НДВ для 24 ГРП и подано 34 декларации о
          НВОС.
        </p>
        <p className="mb-1">
          Для подтверждения соблюдений нормативов допустимых выбросов АО
          «МОСГАЗ» привлекает специализированные и аккредитованные должным
          образом лаборатории:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              для отбора и анализа проб атмосферного воздуха по химическим
              показателям на границах жилых застроек;
            </p>
          </li>
          <li>
            <p>
              для определения инструментальным методом концентраций загрязняющих
              веществ на источниках выбросов загрязняющих веществ в атмосферу
              согласно планам-графикам ведомственного контроля.
            </p>
          </li>
        </ul>
        <p>
          Наличие у Общества Лицензии на осуществление деятельности по сбору,
          транспортированию, обработке, утилизации, обезвреживанию, размещению
          отходов I–IV классов опасности в части транспортирования отходов I–V
          класса опасности позволяет осуществлять самостоятельную перевозку
          строительных отходов и грунтов, образующихся при проведении
          землеройных работ, что полностью исключает затраты на привлечение
          сторонних организаций.
        </p>
        <Header3>
          8.2.3. КАТЕГОРИРОВАНИЕ ОБЪЕКТОВ, ОКАЗЫВАЮЩИХ НЕГАТИВНОЕ ВОЗДЕЙСТВИЕ НА
          ОКРУЖАЮЩУЮ СРЕДУ И ЭКОЛОГИЧЕСКАЯ ОТЧЕТНОСТЬ
        </Header3>
        <p>
          В целях соблюдения природоохранного законодательства все объекты
          АО«МОСГАЗ», оказывающие негативное воздействие на окружающую среду
          (НВОС), подлежат категорированию и постановке на государственный учет.
        </p>
        <p>
          За 2021 год было поставлено на учет 28 объектов с присвоением II
          категории НВОС (по основному виду деятельности).
        </p>
        <p>
          Также Общество регулярно осуществляет формирование и подачу
          экологической отчетности в соответствующие контролирующие органы.
        </p>
        <p className="mb-1">За 2021 год были поданы следующие виды отчетов:</p>
        <ul className="list-disc list-outside">
          <li>
            <p>отчет по форме 2 ТП-отходы по 17 объектам;</p>
          </li>
          <li>
            <p>отчет по форме 2 ТП-воздух по 179 объектам;</p>
          </li>
          <li>
            <p>форма 4-ОС;</p>
          </li>
          <li>
            <p>
              отчеты по программе производственного экологического контроля;
            </p>
          </li>
          <li>
            <p>
              ежеквартальные декларации о внесении авансовых платежей за НВОС.
            </p>
          </li>
        </ul>
        <Header3>8.2.4. ПРОВЕРКИ НАДЗОРНЫМИ ОРГАНАМИ</Header3>
        <p>
          В ноябре 2021 года в АО «МОСГАЗ» была проведена плановая проверка
          Государственной инспекцией в области охраны окружающей среды
          Министерства природных ресурсов и экологии Российской Федерации ФГБУ
          «Национальный парк Лосиный остров» на соблюдение обязательных
          требований природоохранного законодательства РФ в границах
          национального парка «Лосиный остров» по адресу: г. Москва, 1-й
          Белокаменный проезд, 2А, стр.1.
        </p>
        <p>
          По итогам проверки нарушений норм природоохранного законодательства не
          выявлено.
        </p>
        <Header2>8.3. ИНФОРМАЦИЯ ОБ ОХРАНЕ ОБЪЕКТОВ ГАЗОВОГО ХОЗЯЙСТВА</Header2>
        <p>
          В отчетном периоде и по настоящее время под охраной находятся 58
          объектов АО «МОСГАЗ», сформированы 3 круглосуточных подвижных поста.
        </p>
        <p>
          Каждые сутки на дежурство заступают 128 сотрудников охраны (17 со
          служебным оружием, 111 со специальными средствами). Под круглосуточной
          охраной находятся 25 ГРП и Центральный диспетчерский пункт. По заявкам
          управлений на объекты строительства ежедневно выставляется до 37
          постов охраны. Сформированы 3 подвижных поста (автомобиль с водителем
          и сотрудником охраны), которыми осуществляется объезд 503 объектов, в
          том числе ГРП.
        </p>
        <p className="mb-1">
          В целях охраны собственных объектов Общество привлекает частное
          охранное предприятие по договору, заключаемому при проведении
          конкурентных процедур. Охранное предприятие оказывает услуги,
          руководствуясь Федеральным законом от 11.03.1992 № 2487-1 «О частной
          охранной и детективной деятельности в Российской Федерации», другими
          нормативными правовыми актами Российской Федерации, и обеспечивает:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              контроль объекта, закрытой территории и территории ограниченного
              доступа с целью обнаружения возможных опасных ситуаций, которые
              могут дестабилизировать нормальную деятельность предприятия,
              привести к повреждению, разрушению либо уничтожению его объектов и
              находящихся на них материальных ценностей, вызвать угрозу жизни и
              здоровью персонала, принятие своевременных адекватных мер
              противодействия;
            </p>
          </li>
          <li>
            <p>
              осуществление пропускного режима посетителей, транспортных средств
              и грузов на контролируемую территорию с целью установления
              личности и учета посетителей, ввоза и вывоза материальных
              ценностей, предотвращения несанкционированного их перемещения, а
              также фиксацию попыток хищения имущества с охраняемой территории;
            </p>
          </li>
          <li>
            <p>
              обеспечение сохранности конфиденциальных сведений о деятельности
              Общества, предотвращение несанкционированной передачи коммерческой
              информации на физических носителях за пределы охраняемого объекта;
            </p>
          </li>
          <li>
            <p>
              сопровождение материальных ценностей, ценных бумаг (в том числе
              носителей сведений, составляющих государственную тайну) и
              персонала Общества с целью предотвращения причинения вреда либо
              утраты их в период транспортировки;
            </p>
          </li>
          <li>
            <p>
              защита объектов, материальных ценностей, ценных бумаг и персонала
              Общества от насильственных действий и вооруженных нападений со
              стороны преступных элементов.
            </p>
          </li>
        </ul>
        <Header2>
          8.4. ОБЕСПЕЧЕНИЕ ИНФОРМАЦИОННОЙ БЕЗОПАСНОСТИ, ВНЕДРЕНИЕ IT-ТЕХНОЛОГИЙ
          И ЦИФРОВИЗАЦИЯ
        </Header2>
        <p>
          В целях совершенствования работы по технической защите информации от
          иностранных технических разведок и ее утечки по техническим каналам и
          обеспечению безопасности информации, содержащих сведения, составляющих
          государственную тайну, в штатное расписание АО «МОСГАЗ» введена
          должность «ведущий специалист по противодействию иностранным
          техническим разведкам и технической защите информации». Работы по
          данному вопросу проводятся в соответствии с договорами, заключенными
          между Обществом и Федеральным автономным учреждением «Государственный
          научно-исследовательский испытательный институт проблем технической
          защиты информации Федеральной службы по техническому и экспортному
          контролю», а также Федеральным государственным унитарным предприятием
          «Научно-производственное предприятие «Гамма»», в соответствии с
          требованиями руководящих документов и планами работы АО «МОСГАЗ».
        </p>
        <p>
          При этом используются активные и пассивные методы защиты информации с
          применением соответствующих технических и программно-технических
          средств защиты информации от утечки по каналам радио-, оптических и
          электрических сигналов.
        </p>
        <p>
          В соответствии с «Требованиями по технической защите информации,
          содержащей сведения, составляющие государственную тайну»,
          утвержденными приказом ФСТЭК России, во всех режимных и выделенных
          помещениях Общества установлены средства защиты информации, имеющие
          предписания и сертификаты соответствия.
        </p>
        <p>
          Состояние защиты информации и эффективность принимаемых мер по защите
          информации, составляющей государственную тайну, на аттестованных
          объектах информатизации Общества соответствуют требованиям руководящих
          и нормативно-методических документов.
        </p>
        <Header2>8.5. ИНФОРМАЦИЯ О СОБЛЮДЕНИИ ТРЕБОВАНИЙ ГО И ЧС</Header2>
        <Header3>
          8.5.1. МЕРОПРИЯТИЯ ПО МОБИЛИЗАЦИОННОЙ ПОДГОТОВКЕ, ГРАЖДАНСКОЙ ОБОРОНЕ
          И ЗАЩИТЕ ОТ ЧРЕЗВЫЧАЙНЫХ СИТУАЦИЙ
        </Header3>
        <p>
          Задачи в области мобилизационной подготовки, гражданской обороны и
          защиты от чрезвычайных ситуаций (далее – ГО и ЧС), поставленные Мэром
          Москвы, Правительством Москвы, Департаментом жилищно-коммунального
          хозяйства города Москвы, Департаментом по делам гражданской обороны,
          чрезвычайным ситуациям и пожарной безопасности города Москвы, Главным
          управлением МЧС России по городу Москве, Военным комиссариатом города
          Москвы, Генеральным директором АО «МОСГАЗ» на 2021 год, Обществом
          выполнены.
        </p>
        <p>
          Под руководством Генерального директора АО «МОСГАЗ» 27 октября 2021
          года проведены штабная тренировка по ГО и мобилизационная тренировка.
        </p>
        <div className="mt-0">
          <div className="md:max-w-[350px] lg:max-w-[450px] mb-5 ml-5 md:float-right">
            <StaticImage
              src="../images/part-08/8.5.1.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <p className="mb-1">
            В 2021 году АО «МОСГАЗ» приняло участие в учениях и тренировках
            Правительства Москвы:
          </p>

          <ul className="list-outside">
            <li>
              <p>
                13–15 апреля – в командно-штабном учении по отработке вопросов
                ликвидации природных пожаров и обеспечению безаварийного
                пропуска весеннего половодья;
              </p>
            </li>
            <li>
              <p>
                29 апреля – в командно-штабном учении по защите от природных
                пожаров;
              </p>
            </li>
            <li>
              <p>
                6 октября – в штабной тренировке по ГО (в рамках Всероссийской
                штабной тренировки по ГО).
              </p>
            </li>
          </ul>
        </div>
        <p>
          По итогам проведения штабной тренировки по ГО приказом руководителя
          гражданской обороны города Москвы – Мэра Москвы С.С. Собянина от
          01.12.2021 № 2-РГО АО «МОСГАЗ» отмечено в лучшую сторону среди
          организаций города Москвы, сотрудникам Общества объявлены
          благодарности начальника Главного управления МЧС России по городу
          Москве и руководителя Департамента по делам гражданской обороны,
          чрезвычайным ситуациям и пожарной безопасности города Москвы.
        </p>
        <p>
          12–16 мая АО «МОСГАЗ» приняло участие в мероприятиях демонстрационной
          программы XIII международного салона средств обеспечения безопасности
          «Комплексная безопасность-2021» на территории парка «Патриот»
          Московской области с демонстрацией 7 единиц техники для проведения
          аварийно- восстановительных работ на объектах и сетях газового
          хозяйства с показом оборудования и инструментов.
        </p>
        <div
          className="mt-1"
          todo="увеличить под широкий экран, вынести из блока"
        >
          <div className="md:max-w-[350px] lg:max-w-[450px] mb-5 mr-5 md:float-left">
            <StaticImage
              src="../images/part-08/8.5.2.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <p className="max-w-5xl">
            За активное участие в подготовке и проведении международного салона
            Генеральный директор АО «МОСГАЗ», 6 сотрудников Общества награждены
            Почетной грамотой МЧС России, 11 сотрудникам объявлена благодарность
            министра МЧС России.
          </p>
          <p className="max-w-5xl">
            Мероприятия мобилизационной подготовки, ГО и ЧС в течение 2021 года
            проводились в условиях режима повышенной готовности, введенного на
            территории города Москвы в связи с распространением новой
            коронавирусной инфекции (COVID-19).
          </p>
        </div>

        <Header3>8.5.2. МОБИЛИЗАЦИОННАЯ ПОДГОТОВКА</Header3>

        <p>
          В 2021 году в АО «МОСГАЗ» проведено 14 учебно-практических мероприятий
          мобилизационной подготовки, в которых приняли участие 132 человека.
          Учебные цели всех мероприятий достигнуты.
        </p>

        <p>
          10 февраля и 27 июня в Обществе проводились проверки комиссиями
          Военного комиссариата Красносельского района ЦАО города Москвы по
          вопросам исполнения Обществом военно-транспортной обязанности,
          состояния воинского учета и бронирования граждан, пребывающих в
          запасе. По итогам работы комиссией сделаны выводы: транспортные
          средства АО «МОСГАЗ» готовы к передаче в Вооруженные Силы Российской
          Федерации; состояние воинского учета в Обществе соответствует
          требованиям нормативных правовых актов Российской Федерации.
        </p>

        <div className="mt-0">
          <div className="md:max-w-[350px] lg:max-w-[450px] mb-5 mr-5 md:float-left">
            <StaticImage
              src="../images/part-08/8.5.3.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt=""
            />
          </div>
          <p>
            В рамках подготовки к совместному стратегическому учению
            «Запад-2021» 24 августа военным комиссаром Красносельского района и
            2 сентября военным комиссаром города Москвы проведены смотры
            готовности штаба оповещения граждан, пребывающих в запасе и
            работающих в Обществе, и администрации подготовки транспортных
            средств Общества к предоставлению войскам и формированиям в период
            мобилизации и в военное время. По результатам смотров сделаны выводы
            – штаб оповещения и администрация подготовки транспортных средств
            Общества готовы к выполнению задач по предназначению.
          </p>
        </div>

        <Header3>
          8.5.3. ГРАЖДАНСКАЯ ОБОРОНА И ЗАЩИТА ОТ ЧРЕЗВЫЧАЙНЫХ СИТУАЦИЙ
        </Header3>

        <p>
          В течение 2021 года в АО «МОСГАЗ» проведено 23 тренировки по вопросам
          ГО и ЧС, в которых приняли участие 862 человека. Учебные цели
          тренировок достигнуты.
        </p>

        <p>
          В целях предотвращения распространения коронавирусной инфекции
          (COVID-19) подготовка сотрудников АО «МОСГАЗ» по программам курсового
          обучения в области ГО проводилась методом самостоятельного изучения
          материала и дистанционным методом обучения, в том числе с
          использованием корпоративной электронной почты.
        </p>

        <p>
          С вновь принятыми работниками проводился вводный инструктаж по ГО и
          инструктаж по действиям в ЧС с выдачей памяток по действиям при
          получении сигналов ГО, информации об угрозе или возникновении ЧС и по
          соблюдению мер личной безопасности в условиях распространения новой
          коронавирусной инфекции (COVID-19). В течение 2021 года проведены
          инструктажи с 454 работниками Общества.
        </p>

        <p>
          97 должностных лиц и работников АО«МОСГАЗ» прошли в 2021 году
          подготовку в учебно-методических центрах по ГО и ЧС города Москвы и
          ЦАО города Москвы.
        </p>

        <p>
          12–15 февраля проведены выездные проверки АО «МОСГАЗ» Управлением по
          ЦАО Главного управления МЧС России по г. Москве по выполнению
          требований нормативных правовых актов Российской Федерации, города
          Москвы в области ГО и защиты от ЧС. По результатам проверок комиссией
          сделан вывод: требования нормативных правовых актов в области ГО и ЧС
          Обществом выполняются в полном объеме, нарушений не выявлено.
        </p>

        <p>
          <strong>
            Состояние мобилизационной подготовки, ГО и ЧС в АО «МОСГАЗ»
            соответствует требованиям нормативных правовых актов Российской
            Федерации и города Москвы.
          </strong>
        </p>

        <Header3>
          8.5.4. МЕРОПРИЯТИЯ ПО ПРЕДУПРЕЖДЕНИЮ РАСПРОСТРАНЕНИЯ КОРОНАВИРУСНОЙ
          ИНФЕКЦИИ (COVID-19)
        </Header3>

        <p className="mb-1">
          В соответствии с указами Мэра Москвы от 05.03.2020 № 12-УМ «О введении
          режима повышенной готовности», от 08.06.2020 № 68-УМ «Об этапах снятия
          ограничений, установленных в связи с введением режима повышенной
          готовности» и указами Мэра Москвы по внесению в них изменений,
          предписаний и рекомендаций Управления Федеральной службы по надзору в
          сфере защиты прав потребителей и благополучия человека по городу
          Москве в АО «МОСГАЗ» в течение 2021 года продолжались выполняться
          мероприятия по предупреждению распространения новой коронавирусной
          инфекции (COVID-19):
        </p>

        <ol className="list-decimal list-outside">
          <li>
            <p>
              Продолжили работу в целях координации действий Оперативный штаб по
              предупреждению распространения коронавирусной инфекции (COVID-19),
              созданный в 2020 году из числа руководства и должностных лиц АО
              «МОСГАЗ», а также мобильная оперативная группа в его составе.
            </p>
          </li>

          <li>
            <p>
              В 2021 году издано 14 распорядительных документов Общества (12
              приказов и 2 распоряжения).
            </p>
          </li>

          <li>
            <p>
              Всеми сотрудниками выполнялись требования находиться в средствах
              индивидуальной защиты (маски, перчатки) на рабочих местах,
              территории и в зданиях (вне рабочих мест), при производстве работ
              на городских территориях, соблюдать социальную дистанцию.
            </p>
          </li>
          <li>
            <p>
              Проведение бесконтактной термометрии сотрудников, обработка
              помещений дезинфицирующими средствами, дезинфекция специальными
              средствами внутренних дорог и открытых площадок на объектах АО
              «МОСГАЗ» с помощью спецтехники, обеспечение средствами
              индивидуальной защиты (защитными масками и перчатками) сотрудников
              Общества.
            </p>
          </li>
          <li>
            <p>
              Проведение тестирования сотрудников АО «МОСГАЗ» на наличие
              коронавирусной инфекции COVID-19. Всего в 2021 году проведено 3474
              теста у сотрудников, из них 3437 теста – с забором биоматериала
              (мазок), 37 тестов – с забором проб крови на наличие антител.
            </p>
          </li>
          <li>
            <p>
              Продолжена вакцинация сотрудников от коронавирусной инфекции, а в
              ноябре 2021 года начата их ревакцинация. Всего в 2021 году на базе
              АО «МОСГАЗ» прошли ревакцинацию 142 сотрудника Общества.
            </p>
          </li>
          <li>
            <p>
              Организовано информирование сотрудников АО «МОСГАЗ» по соблюдению
              мер личной безопасности путем размещения информационных табло на
              каждом этаже административных зданий, информационных листков на
              информационных стендах и в уголках гражданской обороны в каждом
              структурном подразделении, доведения информационных сообщений
              посредством корпоративной электронной почты и выдачи памяток всем
              сотрудникам Общества.
            </p>
          </li>
        </ol>

        <p>
          <strong>
            Санитарно-эпидемиологические требования, установленные для
            организаций города Москвы, в АО «МОСГАЗ» выполнены в полном объеме.
          </strong>
        </p>

        <Header2>8.6. ПОЖАРНАЯ БЕЗОПАСНОСТЬ</Header2>

        <p>
          В 2021 году Отделом пожарной безопасности проведено 195 проверок
          объектов Общества, из них 65 проверок строительных городков.
        </p>

        <p>
          Совместно с представителями четырех межрайонных прокуратур и
          управления по САО ГУ МЧС России по г. Москве в соответствии со сводным
          графиком Прокуратуры Москвы на 2021 год проведены проверки объектов
          ГРП по 17 адресам. Все мероприятия, обеспечивающие пожарную
          безопасность объектов, выполнены в полном объеме. Основания для
          привлечения АО «МОСГАЗ» к административной ответственности
          отсутствовали.
        </p>

        <SinglePhoto>
          <StaticImage
            src="../images/part-08/8.6.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <p className="mb-1">
          В отчетном периоде Отделом пожарной безопасности принято участие:
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              в проведении штабных тренировок по предупреждению и ликвидации
              чрезвычайных ситуаций и обеспечению пожарной безопасности в трех
              управах районов г. Москвы;
            </p>
          </li>
          <li>
            <p>
              в деятельности рабочей группы по проектированию, строительству,
              техническому перевооружению, капитальному ремонту и реконструкции
              ГРП, ГРПБ, ГРС. Рассмотрены и согласованы технические задания на
              разработку проектно- сметной документации по 15 объектам;
            </p>
          </li>
          <li>
            <p>
              в решении вопросов автоматизации производственных вопросов ГРП,
              ГРПБ, ГРС в части оборудования объектов автоматической пожарной
              сигнализацией, технического обслуживания и
              планово-предупредительного ремонта установок пожарной автоматики;
            </p>
          </li>
          <li>
            <p>
              в совещаниях с работниками органов исполнительной власти и
              организаций города Москвы, специально уполномоченных на решение
              задач в области пожарной безопасности (управления МЧС по: СВАО,
              ЗАО, ЦАО, СЗАО, ЮАО);
            </p>
          </li>
          <li>
            <p>
              3 марта 2021 года Отделом пожарной безопасности принято участие в
              совместном заседании Комиссии Правительства Москвы по
              предупреждению и ликвидации чрезвычайных ситуаций и обеспечению
              пожарной безопасности объектов Москвы и Московской области.
              Подготовлен дополнительный перечень мероприятий, обеспечивающий
              пожарную безопасность объектов, расположенных в лесах,
              лесопарковых и зеленых территориях.
            </p>
          </li>
        </ul>

        <p>
          В адрес ДЖКХ г. Москвы направлены 9 заключений и информационных писем
          по подготовке объектов к летнему пожароопасному периоду с комплексом
          организационно-технических мероприятий.
        </p>

        <p>
          В I квартале 2021 года переработаны, обновлены и дополнены в полном
          объеме в соответствии с требованиями действующих руководящих и
          нормативных документов инструкции о мерах пожарной безопасности для
          всех категорий зданий, строений, сооружений, участков производств,
          помещений, входящих в состав Общества.
        </p>

        <p>
          Подготовлено Распоряжение Общества от 23 августа 2021 года в части
          проведения рейда по внеплановой проверке путей эвакуации,
          эвакуационных выходов, установок пожарной сигнализации на объектах.
        </p>

        <p>
          В шести управах районов г. Москвы проведены совместные заседания
          постоянно действующих рабочих групп по вопросам профилактики
          терроризма, минимизации и ликвидации последствий его проявлений и
          Комиссии по предупреждению и ликвидации чрезвычайных ситуаций и
          обеспечению пожарной безопасности.
        </p>

        <p>
          Совместно с представителями ГУ МЧС России по г. Москве на объектах
          Общества проведены проверки работоспособности на водоотдачу пожарных
          гидрантов наружного противопожарного водоснабжения с пуском воды. Все
          пожарные гидранты исправны, требуемый расход воды на нужды
          пожаротушения обеспечен, техническое обслуживание проводится.
        </p>

        <p>
          Осуществлен контроль в части проведения комплекса технических
          мероприятий специализированной организацией на объектах, в том числе
          техническое обслуживание и планово-предупредительный ремонт установок
          пожарной автоматики (сигнализация, оповещение людей о пожаре,
          спринклерное (водяное), порошковое, газовое пожаротушение,
          дымоудаление, подпор воздуха при пожаре) с соблюдением детальных
          регламентов, норм времени и периодичности выполнения работ в
          соответствии с руководящими и нормативными документами.
        </p>

        <p>
          Восстановлена исполнительная документация ранее смонтированных
          установок автоматической пожарной сигнализации и систем оповещения и
          управления эвакуацией людей при пожаре на 5 объектах.
        </p>

        <p>
          Проведены дополнительные противопожарные инструктажи с сотрудниками,
          состоящими в аварийно-спасательных формированиях, и ответственными по
          пожарной безопасности о действиях в случае пожара и других
          чрезвычайных ситуаций.
        </p>

        <p>
          Проводятся вводные противопожарные инструктажи с вновь прибывшими
          сотрудниками в специально оборудованном классе (Мрузовский пер., 11,
          стр. 1).
        </p>

        <p>
          В результате проведенных проверок объектов Общества установлено, что
          они соответствуют действующим требованиям норм и правил пожарной
          безопасности и могут эксплуатироваться по прямому функциональному
          назначению.
        </p>

        <p className="mb-1">
          <strong>
            Задачи, поставленные Отделом пожарной безопасности АО «МОСГАЗ» на
            2022 год:
          </strong>
        </p>
        <ol className="list-decimal list-outside">
          <li>
            <p>
              Обеспечить проведение специализированной организацией
              категорирования по взрывопожарной и пожарной опасности, класса
              зоны по Правилам устройства электроустановок строений ГРП Общества
              с оформлением отчетных материалов.
            </p>
          </li>
          <li>
            <p>
              Совместно с руководителями структурных подразделений Общества в
              соответствии со Сводным планом проверок Прокуратуры города Москвы
              обеспечить подготовку объектов к проверкам во II полугодии
              органами надзорной деятельности ГУ МЧС России по г. Москве по
              адресам: Мрузовский переулок, строения № 1, 9; Измайловское шоссе,
              д. 47А; 8-я улица Соколиной Горы, д. 4; шоссе Фрезер, д. 15А, стр.
              12.
            </p>
          </li>
          <li>
            <p>
              Продолжить работу по восстановлению исполнительной документации
              смонтированных установок автоматической пожарной сигнализации и
              систем оповещения и управления эвакуацией людей при пожаре на
              объектах Общества.
            </p>
          </li>
          <li>
            <p>
              Организовать проведение проверок состояния огнезащитной обработки
              деревянных конструкций чердаков строений Общества
              специализированной организацией.
            </p>
          </li>
        </ol>

        <NaviNext
          next="/part-9"
          title="РАЗДЕЛ 9. ОСВЕЩЕНИЕ ДЕЯТЕЛЬНОСТИ АО «МОСГАЗ»"
        />
      </TextBlock>
    </Tail>
  );
};

export default Part7;

export const Head = () => (
  <Seo title="РАЗДЕЛ 8. ПРОИЗВОДСТВЕННАЯ БЕЗОПАСНОСТЬ И ЭКОЛОГИЧЕСКАЯ ОТВЕТСТВЕННОСТЬ" />
);
